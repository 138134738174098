exports.components = {
  "component---src-pages-anmeldung-tsx": () => import("./../../../src/pages/anmeldung.tsx" /* webpackChunkName: "component---src-pages-anmeldung-tsx" */),
  "component---src-pages-blog-contentful-component-gallery-card-slug-tsx": () => import("./../../../src/pages/blog/{ContentfulComponentGalleryCard.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-contentful-component-gallery-card-slug-tsx" */),
  "component---src-pages-contentful-iframe-slug-tsx": () => import("./../../../src/pages/{ContentfulIframe.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-iframe-slug-tsx" */),
  "component---src-pages-contentful-page-slug-tsx": () => import("./../../../src/pages/{ContentfulPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-page-slug-tsx" */),
  "component---src-pages-friendsfamily-tsx": () => import("./../../../src/pages/friendsfamily.tsx" /* webpackChunkName: "component---src-pages-friendsfamily-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-unternehmen-zugang-tsx": () => import("./../../../src/pages/unternehmen/zugang.tsx" /* webpackChunkName: "component---src-pages-unternehmen-zugang-tsx" */),
  "component---src-pages-zugang-tsx": () => import("./../../../src/pages/zugang.tsx" /* webpackChunkName: "component---src-pages-zugang-tsx" */)
}

